/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  jQuery(document).ready(function () {
    var windowWidth = $(window).innerWidth();
    $(window).resize(function() {
      windowWidth = $(window).innerWidth();
    });


    var body = $('body');

    if(body.hasClass('home')) {
      setTimeout(function() {
        body.addClass('show-load-animation');
      }, 100);

      setTimeout(function() {
        body.addClass('finish-load-animation');
      }, 1800);

      setTimeout(function() {
        body.addClass('show-page remove-load');

        //boxes_animat();
      }, 2500);
    } else {
      setTimeout(function() {
        body.addClass('show-load-animation');
      }, 100);

      setTimeout(function() {
        body.addClass('finish-load-animation');
      }, 1800);

      setTimeout(function() {
        body.addClass('show-page remove-load');
      }, 2500);
    }

    var navToggler = $('.navbar-toggler');

    navToggler.on('click', function() {
      console.log('click');

      if ($('body').hasClass('opened-menu')) {
        $('body').removeClass('opened-menu');
      } else {
        $('body').addClass('opened-menu');
      }
    });

    // Home boxes animation
    /*var setAnimate,
        flag = 0;

    function boxes_animat() {
      var animate_conteiner = $('#animate-boxes'),
          t = 0,
          H = 517, // height    86 720
          sprH = 31020,//4336, //44462,//61920,   //24336, // Sprite height
          speed = 42;

      if (windowWidth > 1300 ) {
        speed = 45;
      }

      if(flag == 0) {
          flag = 1;

        setAnimate = setInterval(function() {
          t += H; // - 10
          if(t >= sprH) {
            stop_animat();
          } else {
            animate_conteiner.css({'background-position':'0 -'+ t +'px'});
          }
        }, speed);
      }
    }

    function stop_animat() {
      if(flag == 1) {
        flag = 0;
        clearInterval(setAnimate);
      }
    }*/

    // Links logic
    $('a').on('click', function(e) {
      e.preventDefault();

      var href = $(this).attr('href');

      if ($(this).attr('target') == '_blank') {
        window.open(href, "_blank");
      } else if ($(this).hasClass('gallery-item') || $(this).hasClass('menu-toggle-button') || $(this).hasClass('show-more-products')) {
        e.preventDefault();
      } else {
        body.removeClass('show-page');

        setTimeout(function() {
          location.href = href;
        }, 500);
      }
    });


      $('.nav a').on('click', function() {
        if(windowWidth < 992 && $('.navbar-collapse').hasClass('show')) {
          $('button.navbar-toggler').trigger('click');
        }
      });


    //===== Massonry Menus
    $( ".menus-wrap .modal" ).on('show.bs.modal', function() {
      var $thisTarget = $(this).attr('id');
      var selector = '#' + $thisTarget + ' .modal-body';

      setTimeout(function() {
        $(selector).masonry({
          itemSelector: '.menu_section',
          columnWidth: '.menu_section'
        });
      }, 165);
    });


  // Products
  var showMoreProducts = $('.show-more-products');

  showMoreProducts.on('click', function(e) {
    var hiddenItems = $($(this).attr('data-checking-class'));
    var hiddenItemsLen = hiddenItems.length;
    var hiddenItemsLastInd = hiddenItemsLen - 1;

    var i = 0;
    var ind = 0;
    while (ind < 6 && hiddenItemsLen > i) {
      if ($(hiddenItems[i]).hasClass('hidden')) {
        $(hiddenItems[i]).removeClass('hidden');
        ind++;
      }
      i++;
    }

    if (!$(hiddenItems[hiddenItemsLastInd]).hasClass('hidden')) {
      showMoreProducts.addClass('hidden');
    }
  });

    //===== Close menu on esc
    $(document).keyup(function(e) {
      if (e.keyCode === 27) { // esc
        if ($('.navbar-collapse').hasClass('show')) {
          $('button.navbar-toggler').trigger('click');
        }
      }
    });

    // Gallery
    function init_gallery ( $sliderEl, $this) {
      var pswpElement = document.querySelectorAll('.pswp')[0],
          sliderElement = $sliderEl,
          items = [],
          options = {
            history: false,
            focus: false,
            //index: 0,
            showHideOpacity: true,
            clickToCloseNonZoomable: true,
            showAnimationDuration: 0,
            hideAnimationDuration: 0,
            tapToClose: true,
            spacing: 0,
            loop: false,
            preload: [1, 5]
      };

      for( var i = 0; i < sliderElement.length; i ++) {
        var element = $(sliderElement[i]);

        items.push({
          src: element.attr('href'),
          w: element.attr('data-size-width'),
          h: element.attr('data-size-height'),
          title: element.attr('data-title')
        });
      }

      options.index = parseInt( $this.attr('data-index'), 10);

      // Initializes and opens PhotoSwipe
      var galleryLayout = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
      galleryLayout.init();
    }

    $('.photo-gallery-wrapper').on('click', '.gallery-block .gallery-item', function(e) {
      e.preventDefault();
      init_gallery($($(this).closest(".photo-gallery-wrapper" ).attr('data-gallery-id')), $(this));
    });


    if($('.galleries-wrap-part').is('div')) {
      var variableSwiper = new Swiper('.swiper-container', {
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerColumn: 1,
        slidesOffsetAfter: 0,
        spaceBetween: 0,
        loop: false,
        roundLengths: true,
        speed: 700,
        autoplay: false,
        //initialSlide: 1,
        navigation: {
          nextEl: '.swiper-controls .swiper-button-next',
          prevEl: '.swiper-controls .swiper-button-prev'
        },
        pagination: {
          el: '.gallery-pagination',
          type: 'bullets',
          bulletElement: 'span',
          clickable: true
        }
      });
    }


    /* ===== Map section ===== */
    if( $('.map-part').is('div') ) {
      var acfMap = $('.acf-map');

      acfMap.each(function() {
        render_map( $(this) );
      });

      function render_map( $el ) {
          var $markers = $el.find('.marker');
          var args = {
              zoom : 5,
              center : new google.maps.LatLng(0, 0),
              mapTypeId : google.maps.MapTypeId.ROADMAP,
              mapTypeControl: false,
              streetViewControl: false,
              scrollwheel:  false
          };

          var map = new google.maps.Map( $el[0], args);

          map.markers = [];

          $markers.each(function() {
              add_marker( $(this), map );
          });

          center_map( map );
      }

      function add_marker( $marker, map ) {
          var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

          var $pinUrl = $('.hidden-pin-url').text();

          // create marker
          var marker = new google.maps.Marker({
              position : latlng,
              map : map,
              icon:  {
                  url: $pinUrl,
                  scaledSize: new google.maps.Size(40, 40),

                  size: new google.maps.Size(40, 40),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(20, 40)
              },
              optimized: false
          });

          marker.addListener('click', function() {
            infowindow.open(map, marker);

            setTimeout(function() {
              $('.gm-style-iw').parent( "div" ).addClass( "info-window-parent" );
            }, 50);
          });

          var infowindow = new google.maps.InfoWindow({
            content		: $marker.html(),
            maxWidth: 220,
            pixelOffset: new google.maps.Size(-2, 0)
          });

          map.markers.push( marker );

          if( $marker.html() ) {
            google.maps.event.addListener(marker, 'click', function() {
              infowindow.open( map, marker );
            });
          }
      }

      function center_map( map ) {
          var bounds = new google.maps.LatLngBounds();

          $.each( map.markers, function( i, marker ) {
              var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
              bounds.extend( latlng );
          });

          // only 1 marker?
          if( map.markers.length === 1 ) {
              // set center of map
              map.setCenter( bounds.getCenter() );
              map.setZoom( 14 );
          } else {
              map.fitBounds( bounds );
          }
      }
    }
  });


})(jQuery); // Fully reference jQuery after this point.
